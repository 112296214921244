import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import LanguageSelector from '../../components/languageSelector';

import './tutorialHomepageTemplate.scss';

export default function Template({ data, pageContext }) {
  const { mdx } = data; // data.markdownRemark holds our post data
  const { frontmatter, body } = mdx;
  const { locale: currentLocale, availableLocales } = pageContext;

  return (
    <Layout currentLocale={currentLocale}>
      <SEO title={frontmatter.title} description={frontmatter.description} image={frontmatter.featuredImage} />
      {availableLocales.length > 1 ? (
        <LanguageSelector locales={availableLocales} currentLocale={currentLocale} />
      ) : null}
      <main className="tutorial-page page">
        <div className="main-header">
          <h2>{frontmatter.title}</h2>
        </div>
        <section>
          <MDXRenderer>{body}</MDXRenderer>
        </section>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      tableOfContents(maxDepth: 4)
      frontmatter {
        date
        slug
        title
        description
        featuredImage {
          childImageSharp {
            fixed(width: 1200, height: 800) {
              src
            }
          }
        }
      }
    }
  }
`;
